import React from 'react'
import { Link } from 'react-router-dom';
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';

const HeaderContent = () => {
    const style={
        header:{
            'height':'100px',
            'background':'lightblue',
            'text-align':'right',
            'line-height':'100px'
        },

    }
    const headercontent={
        'companyname':'JEEVAN INIT',
        'logo':'',
        'email':'contact@itproskillset.com',
        'phone':'+919640309831, 9493108933'
    };

  return (
    <div>
       <header>
      <div className='h_social_media'>
      
      <Link to='https://www.facebook.com/itproskillset' target='_blank'>
        <i>
      <FacebookIcon />
      </i>
      </Link>

      <Link to='https://www.youtube.com/@itproskillset' target='_blank'>
        <i>
      <YouTubeIcon />
      </i>
      </Link>

      <Link to='https://www.instagram.com/itproskillset' target='_blank'>
        <i>
      <InstagramIcon />
      </i>
      </Link>

      <Link to='https://github.com/ITproSkillSet/' target='_blank'>
        <i>
      <GitHubIcon />
      </i>
      </Link>

      <Link to='#' target='_blank'>
        <i>
      <LinkedInIcon />
      </i>
      </Link>

      <Link to='#' target='_blank'>
        <i>
      <TwitterIcon />
      </i>
      </Link>


     {/*  <i class="fa-brands fa-twitter"></i> 
      <i class="fa-brands fa-instagram"></i> 
      <i class="fa-brands fa-github"></i> 
      <i class="fa-brands fa-linkedin"></i>  */}
      
      </div>

      
        </header> 
    </div>
  )
}

export default HeaderContent