import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';
import ContactForm from './ContactForm';
import { CardMedia, Container } from '@mui/material';
import Typography from '@mui/material/Typography';
import contactimage from '../images/courses/contact.svg';
import Contactmore from './Contactmore';



 const Contact=() =>{

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  return (
    <Container>
      
      <Typography gutterBottom variant="h5" component="div">
        Contact US
        </Typography>
    
        <Typography variant="body2" color="text.secondary">
        For inquiries or assistance, reach out to ITpro SkillSet through email at info@itproskillset.com. Alternatively, you can fill out our contact form on our website. Connect with us via WhatsApp at +91 9640309831. Stay updated and engaged by following us on social media platforms. For face-to-face consultations or visits, our office is located at Dilsukhnagar Office.
        </Typography>

    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid xs={12} sm={12} md={8} lg={8}>
          <Item> 
            <ContactForm />
            </Item>
        </Grid>
        <Grid xs={12} sm={12} md={4} log={4}>
          <Item>
          <CardMedia
              component="img"
              alt="green iguana"
         
              image={contactimage}
              style={{borderRadius:'5px 5px 0px 0px'}}
            />
          </Item>
        </Grid>

        <Grid xs={12} sm={12} md={12} lg={12}>
          <Item> 
        
          <Contactmore />
            </Item>
        </Grid>


      </Grid>
    </Box>
    </Container>
  );
}

export default Contact

