import ListGroup from 'react-bootstrap/ListGroup';
import logo from './ITproSkillSet_Logo.svg';
import HeaderContent from './components/HeaderContent';
function Prefooterleft() {
  return (
    <ListGroup as="ul">
      <ListGroup.Item as="li">
       <img src={logo} alt='' height={80}/>
      </ListGroup.Item>
      <ListGroup.Item as="li">
      
      <i class="fa-solid fa-location-dot"></i> Opp. Konark Theater, Dilsukhnagar, Hyderabad - 500060.
      </ListGroup.Item>

      <ListGroup.Item as="li" disabled style={{display:'flex',alignItems:'center',justifyContent:'space-between',padding:'10px 60px'}}>


      </ListGroup.Item>
     
    </ListGroup>
  );
}

export default Prefooterleft;