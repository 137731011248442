import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import onlinetraining from '../images/ITpro_SkillSet_Online_Training.svg'
import offlinetraining from '../images/courses/class room training.svg'
import weekendtraining from '../images/courses/weekend_training.svg'
import realtimeproject from '../images/courses/Real_time_project.svg'
import corporatetraining from '../images/courses/corporateTraining.svg'
import workshop from '../images/ITpro_SkillSet_Offiline_Training.svg'
import CardMedia from '@mui/material/CardMedia';
import { Container, Typography } from '@mui/material';

function TrainingMode2() {
    const serviceslist=[
        {
            title:'Online Training',
            description:'Dive into the world of MERN, MEAN, React, and Angular development from the comfort of your home with our interactive online training sessions. Our virtual classrooms offer a flexible learning environment where you can engage with instructors, collaborate with peers, and access course materials at your convenience.',
            url:onlinetraining
        },
        {
            title:'Weekend Training',
            description:'Busy schedule? No problem. Our weekend training sessions are tailored to accommodate your commitments while ensuring you receive top-notch instruction in MERN, MEAN, React, and Angular development. Make the most of your weekends and invest in your future with ITpro SkillSet.',
            url:weekendtraining
        },
        {
            title:'Classroom Training',
            description:'Experience the power of in-person learning with our classroom training sessions. Held in conducive environments conducive to focused learning, our classes provide hands-on instruction, live demonstrations, and real-time feedback from expert instructors. Immerse yourself in a supportive learning environment and take your skills to the next level.',
            url:offlinetraining
        },
        {
            title:'Workshops',
            description:'Sharpen your skills and stay updated with the latest trends in web development through our specialized workshops. These intensive sessions offer deep dives into specific topics, allowing you to gain practical insights and refine your expertise in MERN, MEAN, React, and Angular development.',
            url:workshop
        },
        
        {
            title:'Real-Time Projects',
            description:'Put your skills to the test and gain practical experience with our real-time projects. Collaborate with peers, tackle complex challenges, and bring your ideas to life under the guidance of industry experts. Our project-based approach ensures that you are well-prepared to handle real-world scenarios and excel in your professional endeavors.',
            url:realtimeproject
        },
        {
            title:'Corporate Training',
            description:'Empower your team with our customized corporate training solutions. Whether you are looking to upskill your workforce or onboard new talent, our tailored programs cater to the unique needs of your organization. From small teams to large enterprises, ITpro SkillSet is your partner in driving success through skill development.',
            url:corporatetraining
        },
    ]
  return (
    <Container>
   
   <Typography gutterBottom variant="h5" component="div">
   "Unlock Your Potential with ITpro SkillSet's Comprehensive Services"
    </Typography>

    <Typography variant="body2" color="text.secondary">
    "ITproSkillSet" offers comprehensive training in modern IT technologies. Courses cover the MERN and MEAN stacks for full-stack web development, databases including relational and NoSQL, Python for versatile applications, and Java for enterprise-scale software development.
    </Typography>


        <Row xs={1} md={2} className="g-4">
      {serviceslist.map((slist, idx) => (
        <Col key={idx}>
          <Card>
          <CardMedia
        component="img"
        alt="green iguana"
        height="240"
        image={slist.url}
      />
            <Card.Body>
              <Card.Title>{slist.title}</Card.Title>
              <Card.Text>
               {slist.description}
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      ))}
    </Row>
    </Container>
  
  );
}

export default TrainingMode2;